<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import type { ManageAPI } from "~/manage-api/types";
  import LiftCarousel, { type CarouselConfig } from "../carousels/LiftCarousel.svelte";
  import OfferCard from "./OfferCard.svelte";
  import Title from "../common/Title.svelte";
  import SeeAllOffersCard from "./SeeAllOffersCard.svelte";
  import { getOffersByStream } from "./utils";
  import { imageRatioValues } from "../../configuration";
  import { getOneOfferPerStore } from "~/util/offer";
  import type { CarouselInfo } from "../carousels/utils";

  export let class_list = "";
  export let request_store_key: StoresKey;
  export let carouselInfo: CarouselInfo;
  export let offerStreamID = 3;

  const stores_context = getStoresContext(request_store_key);
  const { offers, store } = stores_context;
  const {
    titleTextColor,
    textColor,
    textboxBackgroundColor,
    linkTextbox,
    linkTitle,
  } = carouselInfo;
  
  let filtered_offers: ManageAPI.Offer[] = [];
  let config: CarouselConfig = {};

  $: if ($offers && offerStreamID) {
    filtered_offers = getOneOfferPerStore([...$offers]);
    filtered_offers = getOffersByStream(
      filtered_offers,
      offerStreamID,
      offerStreamID == 1,
    );
    config = {
      aspectRatio: imageRatioValues["OFFER"],
      count: filtered_offers.length + 1,
      initalSlideTo: filtered_offers.length - 3 || 0,
      pos: filtered_offers.length >= 2 ? filtered_offers.length - 2 : 0,
    };
  }

  // For store's own page:
  $: if ($offers && $store) {
    filtered_offers = [...$offers].filter(offer => offer.store?.id === $store.id);
    // Filter out workery offers:
    filtered_offers = filtered_offers.filter(
      offer => !(offer.publicationStreams.length === 1 && offer.publicationStreams[0]?.id === 3)
    );
    config = {
      aspectRatio: imageRatioValues["OFFER"],
      count: filtered_offers.length + 1,
      initalSlideTo: filtered_offers.length - 3 || 0,
      pos: filtered_offers.length >= 2 ? filtered_offers.length - 2 : 0,
    };
  }
</script>

{#if filtered_offers.length}
<div class="offers-lift-wrapper">
  <div class="py-6 {class_list}">
    {#if linkTitle}
      <Title {linkTitle} {titleTextColor} />
    {/if}
    <LiftCarousel {config} isStorePage={!!$store}>
      <svelte:fragment slot="swiper">
        {#each filtered_offers as offer, index}
          <div class="swiper-slide">
            <OfferCard
              {offer}
              {request_store_key} />
          </div>
          {#if index == config.pos && linkTextbox}
            <div class="swiper-slide">
              <SeeAllOffersCard
                {textColor}
                backgroundColor={textboxBackgroundColor}
                {linkTextbox} />
            </div>
          {/if}
        {/each}
      </svelte:fragment>
    </LiftCarousel>
  </div>
</div>
{/if}

<style lang="scss">
  .offers-lift-wrapper {
    background-color: var(--brand-color-1) !important;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/documents/d/mall-of-tripla/suosittuja_tuotteita_juuri_nyt_desktop_pohja-png?download=true) !important;
  }
</style>
