<!-- @format -->
<script lang="ts">
  import type { Link } from "~/components/field-utils";
  export let linkTextbox: Link = {
    text: "",
    href: "",
    outbound: false,
  };
  export let backgroundColor = "#6950a1"
  export let textColor = "#FFF"
  export let aspectRatio = 1  // Default to square if not provided

  const { text, href, outbound } = linkTextbox;

  $: target = outbound == true ? "_blank" : "";
</script>

{#if text && href}
  <a class="card-link" {href} {target}>
    <div 
      class="card-link-image-wrapper"
      style="--aspect-ratio: {aspectRatio * 100}%; background-color: {backgroundColor}; color: {textColor}">
      <div class="card-text">
        <p class="fw-bold">{text}</p>
        <img
          class="lazy-loading-src"
          src="/theme/malloftripla2024/images/point-to-right-white.png"
          alt="point-to-right"
          width="50"
          height="28" />
      </div>
    </div>
  </a>
{/if}

<style lang="scss">
  .card-link {
    display: block;
    position: relative;
    width: 100%;
    color: inherit;
    text-decoration: none;
  }

  .card-link-image-wrapper {
    position: relative;
    width: 100%;
    color: #fff;
    border: 3px solid var(--color-accent-secondary-light);
    overflow: hidden;
    background-image: var(--btn-primary-background-color);
    background-size: cover;
    background-position: center;
    
    &::before {
      content: '';
      display: block;
      padding-top: var(--aspect-ratio, 100%);
    }
  }

  .card-text {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;

    p {
      font-size: 18px;
      line-height: 1.2;
      margin: 0;
      font-weight: 600;
      max-width: 80%;
    }

    img {
      width: 50px;
      margin-top: 1rem;
      height: auto;
    }
  }

  a {
    color: #101820;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
